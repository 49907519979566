<template>
  <div>
    <md-dialog-confirm
      :md-active.sync="dialog_active"
      md-title="删除任务?"
      md-confirm-text="删除"
      md-cancel-text="取消"
      @md-cancel="onCancel"
      @md-confirm="onConfirm" />

    <button class="mdui-fab mdui-color-pink-400 mdui-fab-fixed md-elevation-15"
            style="z-index: 100"
            @click="goToAddPage">
      <i class="mdui-icon material-icons">add</i>
    </button>

<!--    两种查看方式，查看全部和只看指定日期，查看全部采用分页，每页10条-->
    <div class="chip_container">
      <div class="mdui-chip chip_style"
           :class="{ chip_selected: display_all_date }"
           @click="displayAll">
<!--        <div v-show="display_all_date" class="check_icon"><i class="mdui-icon material-icons" style="color: black;">check</i></div>-->
        <span class="mdui-chip-title">查看全部</span>
      </div>

      <div class="mdui-chip chip_style"
           :class="{ chip_selected: !display_all_date }"
           @click="displayTargetDate">
<!--        <div v-show="!display_all_date" class="check_icon"><i class="mdui-icon material-icons" style="color: black;">check</i></div>-->
        <div class="mdui-chip-title" style="padding-right: 6px;">只看 -</div>

        <select class="select_style" v-model="filter_date" v-on:change="displayTargetDate">
          <option v-for="(item,idx) in filter_dates"
                  :value="idx"
                  :key="idx">{{ item.display }}</option>
        </select>
      </div>
    </div>

    <el-empty v-if="tableData.length===0" description="暂无数据" :image-size="80"></el-empty>

    <md-card class="md-card-example md-elevation-1"
             v-for="(item,index) in tableData"
             :key="index"
             style="margin-bottom: 16px">
      <md-card-area md-inset>
        <md-card-header>
          <h2 class="md-title" style="display: flex;justify-content: space-between;">
            <span style="display: flex;align-items: center">{{item.target_date}} {{getDayOfWeek(item.target_date)}}</span>
            <md-chip class="status_chip"
                     :class="getStatusColor(item)">{{getStatusText(item)}}</md-chip>
          </h2>
          <div class="md-subhead" style="display: flex;justify-content: space-between;margin-top: 5px">
            <div style="display: flex;align-items: center;justify-content: center">
              <md-icon>person</md-icon>
              <span style="margin-left: 8px">{{item.account_name}}</span>
              <span style="margin-left: 8px"
                    v-if="item.is_processed && item.task_status===1 && item.auto_pay">{{item.pay_result===1?'已自动支付￥'+extractPaymentNumber(item):'支付失败'}}</span>
            </div>
            <el-statistic
              v-if="!item.is_processed"
              ref="statistic"
              format="HH:mm:ss"
              :value="getCountDownDate(item.target_date)"
              time-indices
              class="count_down"
            ></el-statistic>
          </div>
        </md-card-header>
      </md-card-area>

      <md-card-content style="padding-top: 0;padding-bottom: 0">
        <div class="card-reservation">
          <div class="location_icon">
            <md-icon>location_on</md-icon>
            <span>{{getLocationName(item)}}</span>
          </div>
          <div class="court_detail">
<!--            成功执行的任务，要展示场号-->
            <div v-if="item.task_status===1" class="court_detail_success">
              <div style="white-space: pre-wrap">{{extractCourtNumber(item)}}</div>
              <div class="copy_area">
                <div class="copy_container">
                  <span v-if="copy_text_visible[item.id]">已复制!</span>
                  <i class="mdui-icon material-icons copy_icon"
                     v-else
                     @click="copyToClipboard(item)">content_paste</i>
                </div>
              </div>
            </div>
<!--            其他状态的任务，展示目标时间就行-->
            <div v-if="item.task_status!==1">
              <span>{{getTargetTimeString(item.target_time)}}</span>
            </div>
          </div>
        </div>
      </md-card-content>

      <md-card-expand>
        <md-card-actions md-alignment="right">
          <div>
            <md-button class="md-dense"
                       style="color:rgba(0,0,0,0.42)"
                       @click="deleteTask(item.id)">删除</md-button>
          </div>

          <md-card-expand-trigger>
            <md-button class="md-dense">详情</md-button>
          </md-card-expand-trigger>
        </md-card-actions>
        <md-card-expand-content>
          <md-card-content>
            <div style="white-space: pre-wrap">{{parseItemForDisplay(item)}}</div>
            <br>
            <div style="white-space: pre-wrap">{{parseHTML(item.execution_detail)}}</div>
          </md-card-content>
        </md-card-expand-content>
      </md-card-expand>
    </md-card>

    <el-pagination
      v-if="display_all_date"
      background
      :small="isMobile"
      hide-on-single-page
      layout="total, prev, pager, next"
      @current-change="getPaginatedTaskInfo"
      :page-size="10"
      :pager-count="5"
      :current-page.sync="cur_page"
      :total="task_count">
    </el-pagination>
  </div>
</template>

<script>
import axios from 'axios'
import 'mdui/dist/css/mdui.css'

export default {
  name: 'TaskView',
  computed: {
    filter_dates () {
      // "只看"下拉框日期数据
      const arr = new Array(7)
      for (let i = 0; i < 7; i++) {
        arr[i] = {
          idx: i,
          date: this.getTodayDateString(i),
          display: this.getTodayDateString(i) + ' ' + this.getDayOfWeek(this.getTodayDateString(i))
        }
      }
      return arr
    }
  },
  components: {
  },
  data () {
    return {
      tableData: [],
      dialog_active: false,
      deleted_task_id: null,
      display_all_date: true,
      filter_date: 0,
      message: 'null',
      task_count: 0,
      cur_page: 1,
      isMobile: true,
      copy_text_visible: {} // id:status
    }
  },
  methods: {
    getTodayDateString (idx) {
      // 创建当前日期对象
      const today = new Date()

      // 增加三天
      const threeDaysLater = new Date(today.getTime() + ((idx) * 24 * 60 * 60 * 1000))

      // 格式化输出日期
      const year = threeDaysLater.getFullYear()
      const month = String(threeDaysLater.getMonth() + 1).padStart(2, '0')
      const date = String(threeDaysLater.getDate()).padStart(2, '0')
      const formattedDate = `${year}-${month}-${date}`

      return formattedDate
    },
    displayAll () {
      if (!this.display_all_date) {
        this.display_all_date = true
        this.getPaginatedTaskInfo(this.cur_page)
      }
    },
    displayTargetDate () {
      this.display_all_date = false
      this.getTaskInfoAtTargetDate(this.filter_dates[this.filter_date].date)
    },
    getTaskInfo () {
      axios.post('get_all_tasks', { token: localStorage.getItem('token') }).then(res => {
        this.tableData = res.data.data
        this.tableData.sort(this.compareDate)
      }).catch(err => {
        if (err.response.status === 401) {
          // 身份认证失败，有可能是7天免登录过期了
          localStorage.removeItem('token')
          this.$router.push('/')
          this.$message({
            message: '身份认证过期（失败）！',
            type: 'warning'
          })
        }
      })
    },
    // eslint-disable-next-line camelcase
    getPaginatedTaskInfo (page_number) {
      axios.post('get_paginated_tasks', {
        token: localStorage.getItem('token'),
        page_size: 10,
        page_number: page_number
      }).then(res => {
        this.tableData = res.data.data
        this.tableData.sort(this.compareDate)
      }).catch(err => {
        if (err.response.status === 401) {
          // 身份认证失败，有可能是7天免登录过期了
          localStorage.removeItem('token')
          this.$router.push('/')
          this.$message({
            message: '身份认证过期（失败）！',
            type: 'warning'
          })
        }
      })
    },
    goToAddPage () {
      this.$router.push({
        name: 'add_task',
        params: {
          type: 'test'
        }
      })
    },
    getDayOfWeek (dateString) {
      const daysOfWeek = ['周日', '周一', '周二', '周三', '周四', '周五', '周六']
      const date = new Date(dateString)
      const dayOfWeekIndex = date.getDay()
      return daysOfWeek[dayOfWeekIndex]
    },
    getVenueName (text) {
      // qdb转邱德拔，54转五四
      if (text === 'qdb') {
        return '邱德拔'
      } else {
        return '五四'
      }
    },
    getLocationName (item){
      // 获取展示在card里的场馆名

      // 如果执行失败，直接返回用户下订单时的场馆名
      if(item.task_status !== 1){
        return this.getVenueName(item.venue_type)
      }

      // 否则，返回真正抢到的场馆名，有可能经过了转换
      if(item.execution_detail.includes('疑似长期场占满')){
        // 经过了转换，那就反着来
        if(item.venue_type==="qdb"){
          return "五四"
        }else{
          return "邱德拔"
        }
      }else{
        return this.getVenueName(item.venue_type)
      }
    },
    parseTo24(i){
      // 将输入的整数格式的时间转为09:00的样子
      if(i<=9){
        return "0"+i.toString()+":00"
      }else{
        return i.toString()+":00"
      }
    },
    // eslint-disable-next-line camelcase
    getTargetTimeString (target_time) {
      // eslint-disable-next-line camelcase,no-eval
      target_time = eval(target_time)
      if (target_time.length === 1) {
        return this.parseTo24(target_time[0]) +'-'+ this.parseTo24(target_time[0]+1)
      } else if (target_time.length === 2) {
        return this.parseTo24(target_time[0]) +'-'+ this.parseTo24(target_time[0]+1)+', ' +
            this.parseTo24(target_time[1]) +'-'+ this.parseTo24(target_time[1]+1)
      } else {
        return null
      }
    },
    getStatusText (item) {
      if (item.task_status === 1) {
        // 在任务成功的前提下，如果该任务经过了转换，要把成功标识
        // 改为其他标识符
        if(item.execution_detail.includes('疑似长期场占满')){
          return '转换成功'
        }else{
          return '成功'
        }
      } else if (item.task_status === -1) {
        return '失败'
      } else if (item.task_status === -2) {
        return '未执行'
      } else {
        return '待执行'
      }
    },
    getStatusColor (item) {
      if (item.task_status === 1) {
        if(item.execution_detail.includes('疑似长期场占满')){
          return 'mdui-color-yellow-600'
        }else{
          return 'mdui-color-green-600'
        }
      } else if (item.task_status === -1) {
        return 'mdui-color-red-900'
      } else {
        return 'mdui-color-grey-700'
      }
      // return ''
    },
    compareDate (a, b) {
      const dateA = new Date(a.target_date)
      const dateB = new Date(b.target_date)
      if (dateA < dateB) {
        return 1
      }
      if (dateA > dateB) {
        return -1
      }
      return 0
    },
    deleteTask (id) {
      this.deleted_task_id = id
      this.dialog_active = true
    },
    onCancel () {
      // this.dialog_active = false
    },
    onConfirm () {
      axios.post('delete_task', { task_id: this.deleted_task_id, token: localStorage.getItem('token') }).then(res => {
        if (res.data.code === 200) {
          this.$message({
            message: '删除成功！',
            type: 'success'
          })
          // 删除后要刷新数据，根据用户当前展示的是全部还是制定日期的数据
          // 来决定怎么刷新数据
          this.getTaskCount()
          if (this.display_all_date) {
            this.getPaginatedTaskInfo(this.cur_page)
          } else {
            this.getTaskInfoAtTargetDate(this.filter_dates[this.filter_date].date)
          }
        } else {
          this.$message({
            message: '删除失败！' + res.data.message,
            type: 'error'
          })
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // eslint-disable-next-line camelcase
    getCountDownDate (target_date) {
      const date = new Date(target_date)
      date.setHours(12, 0, 0)
      date.setDate(date.getDate() - 3)
      return date
    },
    parseHTML (text) {
      if (text) {
        const pattern = '\\\\n'
        const target = '\n'
        const reg = new RegExp(pattern, 'g')
        return text.replace(reg, target)
      }
    },
    parseItemForDisplay (item) {
      const display = {}
      display['任务ID'] = item.id
      display['目标日期'] = item.target_date
      display['目标时间'] = item.target_time
      if (item.venue_type === 'qdb') {
        display['目标场馆'] = '邱德拔'
      } else if (item.venue_type === '54') {
        display['目标场馆'] = '五四'
      } else {
        display['目标场馆'] = item.venue_type
      }
      display['目标场号'] = item.court_fav
      display['下单账号'] = item.account_name

      display['自动付款'] = item.auto_pay ? '是' : '否'
      if (item.auto_pay) {
        display['付款方式'] = item.payment_method === 1 ? '场馆余额' : ''
      }

      display['微信提醒'] = item.wechat_message ? '是' : '否'
      if (item.wechat_message) {
        display['微信提醒账号'] = item.wechat_name
      }

      return display
    },
    getTaskCount () {
      axios.post('get_task_count', { token: localStorage.getItem('token') }).then(res => {
        this.task_count = res.data.data
      }).catch(err => {
        console.log(err)
      })
    },
    // eslint-disable-next-line camelcase
    getTaskInfoAtTargetDate (target_date) {
      axios.post('get_tasks_with_target_date', {
        token: localStorage.getItem('token'),
        target_date: target_date
      }).then(res => {
        this.tableData = res.data.data
        this.tableData.sort(this.compareDate)
      }).catch(err => {
        if (err.response.status === 401) {
          // 身份认证失败，有可能是7天免登录过期了
          localStorage.removeItem('token')
          this.$router.push('/')
          this.$message({
            message: '身份认证过期（失败）！',
            type: 'warning'
          })
        }
      })
    },
    extractPaymentNumber(item) {
      const regex = /付款(\d+(\.\d+)?)元/g;
      const matches = regex.exec(item.execution_detail);

      if (matches && matches.length > 1) {
        return parseFloat(matches[1]);
      } else {
        return null;
      }
    },
    extractCourtNumber(item){
      const arr = item.execution_detail.split("\n")
      const info=[] // 初步提取的信息，只是字符串
      let start=false

      for (let i = arr.length - 1; i >= 0; i--) {
        if(arr[i]===null || arr[i]===""){
          continue
        }
        if(start){
          if(arr[i].includes('号场')){
            const colonIndex = arr[i].indexOf(": ");
            info.push(colonIndex >= 0 ? arr[i].substr(colonIndex + 1).trim() : "")
          }else{
            if(arr[i].includes('sleep')){
              continue
            }else{
              break
            }
          }
        }else{
          if(arr[i].includes('3/4:开始获取验证码并验证')){
            start=true
          }
        }
      }

      const finalInfo={} // 从日志里提取的场号信息，格式是{"09-10点","10号场"}
      for(let i=0;i<info.length;i++){
        finalInfo[info[i].split("，")[1]]=info[i].split("，")[0]
      }

      let html=[]
      const targetTime = eval(item.target_time)
      for(const i of targetTime){
        // 将整数时间变成key的格式区去检索场号
        // 有可能出现定两场只抢到了一场的情况，所以检索到了才显示
        const key=this.parseTo24(i).substring(0,2)+"-"+this.parseTo24(i+1).substring(0,2)+"点"
        if(key in finalInfo){
          html.push(this.parseTo24(i)+"-"+this.parseTo24(i+1)+", "+finalInfo[key])
        }
      }

      return html.join("\n")
    },
    copyToClipboard(item) {
      // 时间
      let textToCopy = item.target_date+" "+
          this.getDayOfWeek(item.target_date)+" "
      // 场馆
      if(item.execution_detail.includes('疑似长期场占满')){
        // 经过了转换，那就反着来
        if(item.venue_type==="qdb"){
          textToCopy=textToCopy+"五四"
        }else{
          textToCopy=textToCopy+"邱德拔"
        }
      }else{
        textToCopy=textToCopy+this.getVenueName(item.venue_type)
      }
      // 场号
      textToCopy=textToCopy+"\n"+this.extractCourtNumber(item)


      navigator.clipboard.writeText(textToCopy).then(() => {
        this.$set(this.copy_text_visible, [item.id], true)
        setTimeout(() => {
          this.$set(this.copy_text_visible, [item.id], false)
        }, 3000);
      }, () => {
      });
    }
  },
  mounted () {
    this.getTaskCount()
    this.getPaginatedTaskInfo(1)
  },
  created () {
    this.isMobile = this.$device.mobile
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.md-card {
  display: inline-block;
  vertical-align: top;
  text-align: left;
  width: 100%;
}

.md-icon {
  width: 16px;
  min-width: 16px;
  height: 16px;
  font-size: 16px !important;
  margin: 0;
}

.md-subhead .span{
  vertical-align: middle;
}

.card-reservation {
  display: flex;
  flex-direction: row;
  /*background: yellow;*/
}
.card-reservation .md-icon {
  margin: 0px 8px 0px 0px;
}

.location_icon{
  min-width: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  /*background: red;*/
  height: fit-content;
}

.md-button {
  min-width: 60px;
  border-radius: 2px;
}

::v-deep .el-statistic .con .number{
  font-size: 16px;
}

.chip_container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;
  margin-top: 0;
}

.mdui-chip{
  margin-right: 10px;
}

.status_chip{
  width: 80px;
  height: 35px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.chip_selected{
  background: rgba(0,0,0,0.24);
}

.select_style{
  background: none;
  border: none;
  width: 100%;
  height: inherit;
  appearance: none !important;
  outline: none;
  display: block;
  padding-right: 12px;
}

.check_icon{
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left:4px;
}

.chip_style{
  display: flex;
  flex-direction: row;
}

.count_down{
  width: fit-content;
  align-items: center;
  justify-content: center;
  display: flex;
}

.court_detail{
  /*background: red;*/
}

.court_detail_success{
  display: flex;
  flex-direction: row;
}

.copy_area{
  /*background: green;*/
  display: flex;
  flex-direction: column-reverse;
  /*padding-bottom: 2px;*/
  padding-left: 12px;
}
.copy_container{
  /*background: green;*/
  display: flex;
  flex-direction: row;
  color: rgba(0,0,0,0.54);
  height: 22px;
}

.copy_icon{
  cursor: pointer;
  font-size: 20px;
  display: block;
  color: rgba(0,0,0,0.54);
}
</style>
